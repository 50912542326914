import React from "react";
import { Button } from "@mui/material";
import "./SobreNos.css";
import imagemBuffet from "../../assets/sobre-nos.png"; // Caminho atualizado

const SobreNos = () => {
    const handleScrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    return (
        <section className="sobre-nos">
            <div className="sobre-nos-texto">
                <h2>Sobre nós</h2>
                <h3>
                    Nossa missão é transformar eventos em experiências memoráveis por meio de um serviço gastronômico de alta qualidade.
                    Oferecemos serviço de buffet para eventos corporativos, confraternizações e aniversários, entregando refeições que encantam os sentidos e criam momentos especiais.<br/><br/>
                    Nosso objetivo é devolver o tempo precioso de nossos clientes, proporcionando uma experiência sem burocracia, com eficiência e atenção aos mínimos detalhes.
                    Comprometidos com a excelência, buscamos garantir que cada evento seja perfeito, proporcionando praticidade e garantia de sabor inigualável em cada prato.
                </h3>
                <Button
                    onClick={() => handleScrollToSection("solicite-orcamento")}
                    variant="contained"
                    size="large"
                    sx={{
                        backgroundColor: "#8B5E3C", // Cor principal um pouco mais escura
                        borderRadius: "10px", // Mais arredondado
                        padding: "17px 24px",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        transition: "0.3s",
                        "&:hover": {
                            backgroundColor: "#4a3f35", // Cor do hover
                        },
                    }}
                >
                    Quero um orçamento do buffet
                </Button>
            </div>
            <div className="sobre-nos-imagem">
                <img src={imagemBuffet} alt="Buffet de alta qualidade" />
            </div>
        </section>
    );
};

export default SobreNos;
