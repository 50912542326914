import React from "react";
import "./NossosServicos.css";
import { CheckCircle } from "@mui/icons-material"; // Ícone para a lista

const NossosServicos = () => {
  return (
    <section className="nossos-servicos">
      <div className="servicos-container">
        <div className="texto-servicos">
          <h2>Nossos serviços</h2>
          <p>
            Oferecemos serviço de buffet especial! <br /> Tudo que for necessário para garantir qualidade e conforto aos nossos clientes.
          </p>
        </div>

        <div className="lista-servicos">
          <h3>Comodidades:</h3>
          <div className="comodidades">
            <ul>
              <li>
                <CheckCircle className="icon" /> Montagem do buffet
              </li>
              <li>
                <CheckCircle className="icon" /> Manutenção e reposição da mesa de buffet
              </li>
              <li>
                <CheckCircle className="icon" /> Garçom para servir entradas
              </li>
              <li>
                <CheckCircle className="icon" /> Rechauds
              </li>
              <li>
                <CheckCircle className="icon" /> Panelas
              </li>
            </ul>
            <ul>
              <li>
                <CheckCircle className="icon" /> Grelhas e utensílios de churrasco (se aplicável)
              </li>
              <li>
                <CheckCircle className="icon" /> Pratos de porcelana para refeição principal
              </li>
              <li>
                <CheckCircle className="icon" /> Talheres
              </li>
              <li>
                <CheckCircle className="icon" /> Guardanapos
              </li>
              <li>
                <CheckCircle className="icon" /> Limpeza das mesas
              </li>
            </ul>
          </div>

          <h3>Equipe:</h3>
          <ul>
            <li>
              <CheckCircle className="icon" /> Chef de cozinha
            </li>
            <li>
              <CheckCircle className="icon" /> Assistente de cozinha/Garçons
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default NossosServicos;
