import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setDrawerOpen(false); // Fecha o menu em mobile
  };

  const menuItems = [
    { text: "SOBRE NÓS", id: "sobre-nos" },
    { text: "NOSSOS SERVIÇOS", id: "nossos-servicos" },
    { text: "TIPOS DE BUFFET", id: "tipos-de-buffet" },
    { text: "GALERIA", id: "galeria" },
    { text: "SOLICITE UM ORÇAMENTO", id: "solicite-orcamento" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: isScrolled ? "rgba(0, 0, 0, 0.7)" : "#8B5E3C",
        height: "6rem",
        display: "flex",
        justifyContent: "center",
        boxShadow: isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.2)" : "none",
        transition: "background-color 0.3s, box-shadow 0.3s",
        zIndex: 1201,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingX: { xs: "1rem", md: "9rem" },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontFamily: "'The Seasons', serif",
              fontSize: "1.95rem",
              color: "#fff",
              lineHeight: 1.2,
            }}
          >
            Celebre Buffet
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "'The Seasons', serif",
              fontSize: "0.7rem",
              color: "#fff",
              letterSpacing: "0.05em",
            }}
          >
            GASTRONOMIA | Chef Daniel Alvares
          </Typography>
        </Box>

        {/* Menu Desktop */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 2,
          }}
        >
          {menuItems.map((item, index) => (
            <Button
              key={index}
              onClick={() => handleScrollToSection(item.id)}
              sx={{
                fontSize: "0.8rem",
                textTransform: "none",
                color: item.text === "SOLICITE UM ORÇAMENTO" ? "#fff" : "inherit",
                padding: item.text === "SOLICITE UM ORÇAMENTO" ? "0.5rem 1.5rem" : "0",
                backgroundColor: item.text === "SOLICITE UM ORÇAMENTO" ? "#8B5E3C" : "transparent",
                borderRadius: item.text === "SOLICITE UM ORÇAMENTO" ? "1.5rem" : "0",
                border: item.text === "SOLICITE UM ORÇAMENTO" ? "1px solid #fff" : "none",
                "&:hover": {
                  backgroundColor: item.text === "SOLICITE UM ORÇAMENTO" ? "#A67C52" : "transparent",
                  color: item.text === "SOLICITE UM ORÇAMENTO" ? "#fff" : "#cbae99",
                },
              }}
            >
              {item.text}
            </Button>
          ))}
        </Box>

        {/* Menu Mobile - Botão */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Drawer - Menu Mobile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          zIndex: 1301, // Maior que o zIndex do AppBar (1201)
          "& .MuiDrawer-paper": {
            zIndex: 1301, // Ajusta a camada do Drawer
          },
        }}
      >

        <List sx={{ width: 250 }}>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => handleScrollToSection(item.id)}>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
