import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./Galeria.css";

const Galeria = () => {
    const [imagens, setImagens] = useState([]);

    // Carregar imagens do JSON na pasta public
    useEffect(() => {
        fetch("/galeria/lista.json") // Busca o JSON na pasta public
            .then((res) => res.json())
            .then((data) => {
                const imagensCarregadas = data.imagens.map((img) => `/galeria/${img}`);
                setImagens(imagensCarregadas);
            })
            .catch((err) => console.error("Erro ao carregar imagens:", err));
    }, []);

    const [imagemSelecionada, setImagemSelecionada] = useState(null);

    const abrirImagem = (src) => {
        setImagemSelecionada(src);
    };

    const fecharImagem = () => {
        setImagemSelecionada(null);
    };

    return (
        <section className="galeria-container">
            {/* Título da Galeria */}
            <h2 className="galeria-titulo">Galeria</h2>

            {/* Carrossel de imagens */}
            <Swiper
                navigation={true}
                slidesPerView={3}
                spaceBetween={0}
                loop={true}
                breakpoints={{
                    320: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                }}
                modules={[Navigation]}
                className="galeria-swiper"
            >
                {imagens.map((imagem, index) => (
                    <SwiperSlide key={index} className="galeria-slide">
                        <div className="galeria-imagem-container" onClick={() => abrirImagem(imagem)}>
                            <img src={imagem} alt={`Imagem ${index + 1}`} className="galeria-imagem" />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Modal para exibir imagem ampliada */}
            {imagemSelecionada && (
                <div className="modal-overlay" onClick={fecharImagem}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="fechar-modal" onClick={fecharImagem}>✕</button>
                        <img src={imagemSelecionada} alt="Imagem Expandida" className="imagem-expandida" />
                    </div>
                </div>
            )}
        </section>
    );
};

export default Galeria;
