import { Helmet, HelmetProvider } from "react-helmet-async";
import './App.css';
import Navbar from "./components/navBar/NavBar";
import SobreNos from "./components/sobreNos/SobreNos";
import NossosServicos from "./components/nossosServicos/NossosServicos";
import TiposBuffet from "./components/tiposBuffet/TiposBuffet";
import Galeria from "./components/galeria/Galeria";
import Formulario from './components/formulario/Formulario';
import Footer from './components/footer/Footer';
import WhatsAppButton from "./components/whatsapp/WhatsAppButton";

function App() {
  return (
    <HelmetProvider>
      <div>
        {/* SEO Config */}
        <Helmet>
          <title>Celebre Buffet | Transformamos seu evento em uma experiência memorável</title>
          <meta name="description" content="Oferecemos serviço de buffet para eventos corporativos, confraternizações e aniversários, com refeições de alta qualidade que encantam os sentidos e criam momentos inesquecíveis." />
          <meta name="keywords" content="buffet para eventos, eventos corporativos, confraternizações, aniversários, serviço gastronômico, alta qualidade, refeições especiais, experiência sem burocracia, praticidade e sabor, momentos inesquecíveis" />
          <meta name="author" content="Buffet Exclusivo" />

          {/* Open Graph (Facebook, LinkedIn, WhatsApp) */}
          <meta property="og:title" content="Buffet Exclusivo - Transformamos seu evento em uma experiência memorável" />
          <meta property="og:description" content="Buffet de alta qualidade para eventos corporativos, aniversários e confraternizações. Sem burocracia, com eficiência e atenção aos detalhes." />
          <meta property="og:image" content="URL_DA_IMAGEM" />
          <meta property="og:url" content="celebrebuffet.com.br" />
          <meta property="og:type" content="website" />

          {/* Twitter Cards */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Buffet Exclusivo - Qualidade e sabor para seu evento" />
          <meta name="twitter:description" content="Serviço de buffet completo para eventos inesquecíveis. Qualidade, praticidade e sabor garantidos!" />
          <meta name="twitter:image" content="URL_DA_IMAGEM" />
        </Helmet>

        <Navbar />
        <div id="sobre-nos"><SobreNos /></div>
        <div id="nossos-servicos"><NossosServicos /></div>
        <div id="tipos-de-buffet"><TiposBuffet /></div>
        <div id="galeria"><Galeria /></div>
        <div id="solicite-orcamento"><Formulario /></div>
        <Footer />
        <WhatsAppButton />
      </div>
    </HelmetProvider>
  );
}

export default App;
