import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PixIcon from "@mui/icons-material/Pix";
//import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
//import TikTokIcon from "@mui/icons-material/MusicNote";
import LogoCelebre from "../../assets/LogoCelebreBuffet.png"

const Footer = () => (
  <Box sx={{ backgroundColor: "#000", padding: 4, color: "#FFF" }}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
          <img
            src={LogoCelebre}
            alt="Logo Celebre Buffet"
            style={{
              width: "172px",
              height: "150px", // Mantendo altura igual à largura para um círculo perfeito
              marginBottom: "-30px"
            }}
          />
        </Box>

        <b style={{ fontSize: "25px" }}>Celebre Buffet - Brasília</b>
        <Typography variant="body2" sx={{ fontWeight: 500, color: "#FFF", marginTop: 1 }}>
          CNPJ: 59.424.757/0001-55
        </Typography>
      </Grid>

      {/* Contato */}
      <Grid item xs={12} md={4} style={{ marginTop: "9px" }}>
        <Typography variant="h6">Contato</Typography>
        <Typography>E-mail: contato@celebrebuffet.com.br</Typography>
        <Typography>Telefone: (61) 99335-9754</Typography>

        {/* Ícones de Redes Sociais */}
        <Box sx={{ display: "flex", gap: 0, marginTop: 2 }} style={{ alignItems: "Center" }}>
          {/*
          <IconButton
            href="https://www.facebook.com/people/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "#FFF" }}
          >
            <FacebookIcon fontSize="large" />
          </IconButton>
          */}
          <IconButton
            href="https://www.instagram.com/celebrebuffet.brasilia/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "#FFF", marginLeft: "-12px" }}
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
          <a href="https://www.instagram.com/celebrebuffet.brasilia/"
            rel="noopener"
            style={{ color: "#FFF", textDecoration: "none", fontFamily: "'Roboto','Helvetica','Arial',sans-serif", fontSize: "18px" }}
          >@celebrebuffet.brasilia
          </a>
          {/*
          <IconButton
            href="https://www.tiktok.com/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "#FFF" }}
          >
            <TikTokIcon fontSize="large" />
          </IconButton>
          */}
        </Box>
      </Grid>

      {/* Métodos de Pagamento */}
      <Grid item xs={12} md={4} style={{ marginTop: "9px" }}>
        <Typography variant="h6">Métodos de Pagamento</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 1 }}>
          <PaymentOption icon={<PixIcon />} text="PIX" />
          <PaymentOption icon={<CreditCardIcon />} text="Cartão de débito" />
          <PaymentOption icon={<CreditCardIcon />} text="Cartão de crédito em até 3x" />
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Componente auxiliar para os métodos de pagamento
const PaymentOption = ({ icon, text }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    {icon}
    <Typography>{text}</Typography>
  </Box>
);
export default Footer;
