import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Fab } from "@mui/material";

const WhatsAppButton = () => {
  const phoneNumber = "5561993359754"; // Substitua pelo número do WhatsApp
  const message = "Olá! Gostaria de mais informações do buffet.";
  const encodedMessage = encodeURIComponent(message); // Codifica a mensagem no formato de URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 20, // Posição fixa no rodapé da tela
        right: 20, // Posição fixa no lado direito
        zIndex: 9999, 
      }}
    >
      <Fab
        color="success" // Cor verde para o botão
        href={whatsappUrl} // URL com a mensagem pré-escrita
        target="_blank" // Abre em uma nova guia
        rel="noopener noreferrer" // Segurança adicional
        aria-label="WhatsApp"
      >
        <WhatsAppIcon sx={{ fontSize: 32 }} />
      </Fab>
    </Box>
  );
};

export default WhatsAppButton;
