import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import CountrySelect from "./CountrySelect";
import chef from "../../assets/chef.jpg";
import emailjs from "@emailjs/browser";


import "./Formulario.css";

const Formulario = () => {
  const [formData, setFormData] = useState({
    nome: "",
    telefone: "",
    email: "",
    local: "",
    convidados: "",
    descricao: "",
    codigoPais: "+55",
  });

  // Atualiza o estado do código do país quando o usuário escolhe outro
  const handleCountryChange = (newValue) => {
    console.log("Código do país atualizado:", newValue);
    setFormData((prev) => ({ ...prev, codigoPais: newValue }));
  };

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "convidados") {
      const numericValue = value.replace(/\D/g, "").slice(0, 4);
      setFormData({ ...formData, [name]: numericValue });
    } else if (name === "email") {
      setFormData({ ...formData, [name]: value });

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(value)) {
        setErrors({ ...errors, email: "Digite um e-mail válido" });
      } else {
        setErrors({ ...errors, email: "" });
      }
    } else {
      setFormData({ ...formData, [name]: value });

      if (value.trim() !== "") {
        setErrors({ ...errors, [name]: "" });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validação dos campos obrigatórios
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key] || formData[key].toString().trim() === "") {
        newErrors[key] = "Campo obrigatório";
      }
    });

    // Se houver erros, exibe as mensagens e interrompe o envio
    if (Object.keys(newErrors).length > 0) {
      console.log("Entrei aqui 1" + newErrors.Object);
      setErrors(newErrors);
      console.log("Entrei aqui 2");
      return;
    }

    // Dados para o EmailJS
    const templateParams = {
      nome: formData.nome,
      codigoPais: formData.codigoPais || "", // Evita erro se for null
      telefone: formData.telefone,
      email: formData.email,
      local: formData.local,
      convidados: formData.convidados,
      descricao: formData.descricao,
    };

    emailjs
      .send(
        "service_aakkpgd",  // Service ID
        "template_0yllyzk",  // Template ID
        templateParams,
        "tYhTeAxPetkoqGYWX"  // Você precisa adicionar essa chave pública do EmailJS
      )
      .then((response) => {
        alert("Recebemos sua solicitação de orçamento e estamos analisando as informações. Em breve, nossa equipe entrará em contato para fornecer mais detalhes.\n\nAgradecemos pelo seu interesse em nossos serviços!\n\nAtenciosamente,\nCelebre Buffet - Brasília");

        // Limpar formulário após envio bem-sucedido
        setFormData({
          nome: "",
          codigoPais: "",
          telefone: "",
          email: "",
          local: "",
          convidados: "",
          descricao: "",
        });

        // Limpar erros
        setErrors({});
      })
      .catch((err) => {
        console.error("Erro ao enviar o email:", err);
        alert("Erro ao enviar o formulário. Por favor, tente novamente.");
      });
  };

  return (
    <div className="formulario-container">
      <div className="formulario-content">
        <div className="formulario-esquerda">
          <h1>Falar sobre buffet para eventos</h1>
          <p style={{ fontSize: "1.3rem" }}>Preencha o formulário abaixo e fale conosco pelo WhatsApp.</p>
          <form onSubmit={handleSubmit}>

            <TextField
              name="nome"
              label="Nome completo"
              variant="filled"
              fullWidth
              size="small"
              value={formData.nome}
              onChange={handleChange}
              error={Boolean(errors.nome)}
              helperText={errors.nome}
              margin="normal"
              sx={{
                backgroundColor: "white",
                "& .MuiInputLabel-root.Mui-focused": { color: "#A67C50" },
                "& .MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiFilledInput-root:after": { borderBottom: "2px solid #A67C50" }
              }}
            />

            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <CountrySelect onChange={handleCountryChange} />
              <TextField
                name="telefone"
                label="Telefone"
                variant="filled"
                fullWidth
                size="small"
                value={formData.telefone}
                onChange={handleChange}
                error={Boolean(errors.telefone)}
                helperText={errors.telefone}
                margin="normal"
                sx={{
                  backgroundColor: "white",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#A67C50" },
                  "& .MuiFilledInput-root": { backgroundColor: "white" },
                  "& .MuiFilledInput-root:after": { borderBottom: "2px solid #A67C50" }
                }}
              />
            </div>

            <TextField
              name="email"
              label="Email"
              variant="filled"
              fullWidth
              size="small"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
              margin="normal"
              sx={{
                backgroundColor: "white",
                "& .MuiInputLabel-root.Mui-focused": { color: "#A67C50" },
                "& .MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiFilledInput-root:after": { borderBottom: "2px solid #A67C50" }
              }}
            />

            <TextField
              name="local"
              label="Local do evento"
              variant="filled"
              fullWidth
              size="small"
              value={formData.local}
              onChange={handleChange}
              error={Boolean(errors.local)}
              helperText={errors.local}
              margin="normal"
              sx={{
                backgroundColor: "white",
                "& .MuiInputLabel-root.Mui-focused": { color: "#A67C50" },
                "& .MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiFilledInput-root:after": { borderBottom: "2px solid #A67C50" }
              }}
            />

            <TextField
              name="convidados"
              label="Número de convidados"
              variant="filled"
              fullWidth
              size="small"
              type="text"
              value={formData.convidados}
              onChange={handleChange}
              error={Boolean(errors.convidados)}
              helperText={errors.convidados}
              margin="normal"
              sx={{
                backgroundColor: "white",
                "& .MuiInputLabel-root.Mui-focused": { color: "#A67C50" },
                "& .MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiFilledInput-root:after": { borderBottom: "2px solid #A67C50" }
              }}
            />

            <TextField
              name="descricao"
              label="Breve descrição do evento"
              variant="filled"
              fullWidth
              size="small"
              multiline
              rows={4}
              value={formData.descricao}
              onChange={handleChange}
              error={Boolean(errors.descricao)}
              helperText={errors.descricao}
              margin="normal"
              sx={{
                backgroundColor: "white",
                "& .MuiInputLabel-root.Mui-focused": { color: "#A67C50" },
                "& .MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiFilledInput-root:after": { borderBottom: "2px solid #A67C50" }
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "20px",
                "&:hover": { backgroundColor: "#4a3f35", color: "white" }
              }}
            >
              Enviar
            </Button>
          </form>
        </div>
        <div className="formulario-direita">
          <img src={chef} alt="Chef preparando comida" className="chef-imagem" />
        </div>
      </div>
    </div>
  );
};

export default Formulario;
