import React from "react";
import { motion } from "framer-motion";
import "./TiposBuffet.css";

// Importação das imagens
import feijoada from "../../assets/feijoada.png";
import churrasco from "../../assets/churrasco.png";
import churrascoPremium from "../../assets/churrascoPremium.png";
import boteco from "../../assets/boteco.png";

// Dados dos pratos
const pratos = [
  {
    id: 1,
    nome: "Feijoada Imperial",
    imagem: feijoada,
    descricao: "Rodízio volante com entradas + buffet de feijoada.",
    entradas: ["Pastel de queijo", "Caldinho de feijão", "Linguiçinha com mandioca"],
    mesaPrincipal: [
      "Arroz branco",
      "Feijão preto (vegano)",
      "Feijoada (feijão, bacon, costelinha, carne seca, lombo, calabresa e paio)",
      "Linguiça calabresa a parte",
      "Couve na manteiga",
      "Farofa de cebola",
      "Torresmo",
      "Laranja",
      "Mix de salada",
    ],
  },
  {
    id: 2,
    nome: "Churrasco Tradicional",
    imagem: churrasco,
    descricao:
      "Rodízio volante de carnes + buffet de carnes e guarnições.",
    acompanhamentos: [
      "Arroz branco",
      "Feijão tropeiro",
      "Mandioca na manteiga",
      "Vinagrete",
      "Pão de alho",
      "Coxinha de frango",
      "Coração de galinha",
      "Picanha",
      "Bife ancho ou baby beef",
    ],
  },
  {
    id: 3,
    nome: "Churrasco Premium",
    imagem: churrascoPremium,
    descricao:
    "Rodízio volante de carnes + buffet de carnes e guarnições.",
    acompanhamentos: [
      "Arroz branco",
      "Arroz parrillero",
      "Feijão tropeiro",
      "Mandioca na manteiga",
      "Vinagrete",
      "Pão de alho",
      "Queijo coalho",
      "Coxinha de frango",
      "Coração de galinha",
      "Picanha",
      "Chorizo angus",
      "Fraldinha red",
    ],
  },
  {
    id: 4,
    nome: "Comidas de Boteco",
    imagem: boteco,
    descricao: "Rodízio volante + buffet para servir à vontade.",
    acompanhamentos: [
      "Carne de sol em cubos",
      "Calabresa acebolada",
      "Frango a passarinho",
      "Batata frita",
      "Pastel de queijo",
      "Caldinho de feijão",
    ],
  },
];

const TiposBuffet = () => {
  return (
    <section className="tipos-buffet">
      <h2 className="titulo">Nosso Cardápio</h2>

      <div className="pratos-container">
        {pratos.map((prato, index) => (
          <motion.div
            key={prato.id}
            className="prato"
            whileInView={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <img src={prato.imagem} alt={prato.nome} className="prato-img" />
            <h3 className="prato-nome">{prato.nome}</h3>

            {prato.descricao && <p className="descricao">{prato.descricao}</p>}

            {prato.entradas && (
              <>
                <h4>Entradas:</h4>
                <ul className="lista">
                  {prato.entradas.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </>
            )}

            {prato.mesaPrincipal && (
              <>
                <h4>Mesa Principal:</h4>
                <ul className="lista">
                  {prato.mesaPrincipal.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </>
            )}

            {prato.acompanhamentos && (
              <>
                <h4>Acompanhamentos:</h4>
                <ul className="lista">
                  {prato.acompanhamentos.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </>
            )}
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default TiposBuffet;
